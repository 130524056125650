import React from 'react'

const containerStyle = {
    backgroundColor: 'white',
    marginTop: '2rem',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    padding: 0,
    position: 'absolute',
    width: '80%',
    marginLeft: '10%'
    
}

const colStyle = {
    textAlign: 'left',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    margin: '0.75rem',
    padding: 0
}

const colHeaderStyle = {
    backgroundColor: 'green',
    color: 'white',
    padding: '0.45rem'
}

export default function Projects() {
    return (
        <div style={containerStyle} className='content-container'>
            <h1 style={{ backgroundColor: 'lightgreen', padding: '0.50rem'}}>Projects</h1>
            <div className='two-col'>
                <div style={colStyle} className='col'>
                    <h3 style={colHeaderStyle}>StakSwipe</h3>
                    <div style={{ padding: '0.5rem'}}>
                        <strong>Github:</strong><a href='https://github.com/michealmikeyb/FlutterStak'>StakSwipe</a>
                        <br/>
                        <strong>Website:</strong><a href='https://stakswipe.com'>stakswipe.com</a>
                        <br/>
                        <strong>Play Store: </strong><a href='https://play.google.com/store/apps/details?id=com.stakswipe'>App</a>
                        <br/>
                        <strong>Project Description:</strong>
                            A flutter based mobile application for media aggregation. It pulls from both the stakswipe
                            server hosted on google firebase as well as the reddit api. The interface is swipe based 
                            similar to tinder where the user swipes right on content they like and swipes left on 
                            content they don't. The application then customizes the cards shown to the user based 
                            on their likes. Users can also share cards to those who follow them.
                        <br/>
                    </div>
                </div>
                <div style={colStyle} className='col'>
                    <h3 style={colHeaderStyle}>Free Template</h3>
                    <div style={{ padding: '0.5rem'}}>
                        <strong>Github:</strong><a href='https://github.com/michealmikeyb/FreeTemplate'>Free Template</a>
                        <br/>
                        <strong>Project Description:</strong>
                            A Template extension for brackets, a web development oriented text editor. The extension allows
                            for multiple different templates based on file extensions. The templates are customizable by the 
                            user. The extension is available in the brackets extension registry for any one to download and use.
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    )
}
