import React from 'react'

const containerStyle = {
    backgroundColor: 'white',
    marginTop: '2rem',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    padding: 0,
    position: 'absolute',
    width: '80%',
    marginLeft: '10%'
    
}

const colStyle = {
    textAlign: 'left',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    margin: '0.75rem',
    padding: 0
}

const colHeaderStyle = {
    backgroundColor: 'navy',
    color: 'white',
    padding: '0.45rem'
}

export default function Experience() {
    return (
        <div style={containerStyle} className='content-container'>
            <h1 style={{ backgroundColor: 'aquamarine', padding: '0.50rem'}}>Experience</h1>
            <div className='two-col'>
                <div style={colStyle} className='col'>
                    <h3 style={colHeaderStyle}>Versant Medical Physics</h3>
                    <div style={{ padding: '0.5rem'}}>
                        <strong>Location:</strong>Kalamazoo Michigan
                        <br/>
                        <strong>Title:</strong>Software Engineer I
                        <br/>
                        <strong>Project Description:</strong>
                            A Django based web application for helping radiation safety officers to manage 
                            there employees and equipment. Included tools for scheduling, inventory management, 
                            radiation dose tracking and alerting, radiation safety education courses and cloud 
                            based document storage. Used by hospitals and pharmecutical companies to keep 
                            track of their radiation safety.
                        <br/>
                        <strong>My Responsibilities:</strong>
                            I was a fullstack developer and worked all around the application touching on all 
                            parts of the project from testing to bug fixing to feature Development and implementation. 
                            one of the overarching projects I worked on was the templating system which allowed for 
                            custom templates with varying amounts and types of fields to be used for types of equipment.
                            I also worked heavily with our test suite to make sure everything was working as it was 
                            supposed to.
                        <br/>
                        <strong>Reason For Leaving: </strong>Location
                    </div>
                </div>
                <div style={colStyle} className='col'>
                    <h3 style={colHeaderStyle}>Celadon Solutions Group</h3>
                    <div style={{ padding: '0.5rem'}}>
                        <strong>Location:</strong>Grosse Pointe Michigan
                        <br/>
                        <strong>Title:</strong>Software Engineer
                        <br/>
                        <strong>Project Description:</strong>
                            A Django based front end website to show the services for the company as well as ways to
                            connect and contact the company. This was combined with a bidding application that allowed
                            cannabis dispensaries and grow operations to advertise for deliveries which would be bid on 
                            by companies licensed for secure cannabis and currency transportation.
                        <br/>
                        <strong>My Responsibilities:</strong>
                            I am the sole developer of the web application and have the seen the project through from the 
                            beginning. I programmed both the front end with jquery and django's templating system as well 
                            as the backend with django. I deployed it to Amazon web services elastic beanstalk environment
                            and have been regularly updating it at the request of the owners. I also utilized AWS for other
                            general dev ops such as setting up the email for the company, transferring the domain and utilizing 
                            RDS to create a postgres database.
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    )
}
