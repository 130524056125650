import React, { Component } from 'react'
import axios from 'axios'
import FlashMessage from 'react-flash-message'


export default class Contact extends Component {

    flashStyle = {
        width: '100%' ,
        backgroundColor: 'lightgreen', 
        padding: '0.3rem', 
        textAlign: 'center'
    }

    state = {
        subject: '',
        replyEmail: '', 
        message: '',
        flash: ''
    }

    containerStyle = {
        backgroundColor: 'white',
        marginTop: '2rem',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        padding: 0,
        position: 'absolute',
        width: '80%',
        marginLeft: '10%'
        
    }

    formStyle = {
        textAlign: 'left',
        padding: '0.5rem',
    }

    btnStyle = {
        backgroundColor: 'lightgreen',
        cursor: 'pointer',
        color: 'black',
        border: 'none',
        padding: '0.5rem'
    }

    submit = (e) => {
        e.preventDefault();
        axios.post('https://www.celadonsg.com/contact-developer', {
            subject: this.state.subject,
            email: this.state.replyEmail,
            message: this.state.message
        }).then((res)=>{
            this.setState({
                flash: <FlashMessage duration={5000}>
                            <div style={this.flashStyle}>
                                Message Sent
                            </div>
                        </FlashMessage>,
                subject: '',
                replyEmail: '',
                message: ''
            })
        })
    }

    changeInput = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <div style={this.containerStyle} className='content-container'>
                <h1 style={{ backgroundColor: 'lightseagreen', padding: '0.50rem'}}>Contact</h1>
                <form onSubmit={this.submit} method="post" style={this.formStyle}>
                    {this.state.flash}
                    <strong>Subject:</strong>
                    <br/>
                    <input name='subject' onChange={this.changeInput} value={this.state.subject} style={{ width: '100%'}}/>
                    <br/>
                    <br/>
                    <strong>Reply Email:</strong>
                    <br/>
                    <input name='replyEmail' onChange={this.changeInput} value={this.state.replyEmail} style={{ width: '100%'}}/>
                    <br/>
                    <br/>
                    <strong>Message:</strong>
                    <br/>
                    <textarea name='message' onChange={this.changeInput} style={{ width: '100%', height: '10rem'}} value={this.state.message}></textarea>
                    <br/>
                    <button style={this.btnStyle} >Send</button> 
                </form>
            </div>
        )
    }
}

