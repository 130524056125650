import React from 'react'

const style = {
        backgroundColor: 'white',
        color: '#515151',
        fontWeight: 'bold',
        width: '30%',
        padding: '30px',
        textAlign: 'center',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        margin: 'auto',
        marginTop: '15%',
        marginLeft: '35%',
        position: 'absolute'
  }
export default function HomePage() {
    return (
        <div style={style} className='name-card'>
            <h1>Michael Mitchell</h1>
            <h3>Software Engineer</h3>
        </div>
    )
}
