import React from 'react';
import kcollege from '../../images/kcollege.png';
import SIP from '../../images/SIP.pdf';
import history from '../../images/history.pdf';

const containerStyle = {
    backgroundColor: 'white',
    marginTop: '2rem',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    padding: 0,
    position: 'absolute',
    width: '80%',
    marginLeft: '10%'
    
}

const colStyle = {
    textAlign: 'left',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    margin: '0.75rem',
    padding: 0
}

const colHeaderStyle = {
    backgroundColor: 'black',
    color: 'orange',
    padding: '0.45rem'
}

export default function Education() {
    return (
        <div style={containerStyle} className='content-container'>
            <h1 style={{ backgroundColor: 'orange', padding: '0.50rem'}}><img src={kcollege} alt='' style={{ height: '2rem'}}/>alamazoo College</h1>
            <div className='two-col'>
                <div style={colStyle} className='col'>
                    <h3 style={colHeaderStyle}>Academics</h3>
                    <div style={{ padding: '0.5rem'}}>
                        <strong>Major:</strong>Computer Science
                        <br/>
                        <strong>Minor:</strong>History
                        <br/>
                        <strong>GPA:</strong>3.38
                        <br/>
                        <strong>Computer Science GPA: </strong>3.75
                        <br/>
                        <strong>Deans list Fall 2016 and Winter 2017</strong>
                        <br/>
                        <strong>Course Work:</strong>
                        <ul>
                            <li>Data Structures</li>
                            <li>Design and Analysis of Algorithms</li>
                            <li>Computer Organization</li>
                            <li>Principles of Programming Languages</li>
                            <li>Free and Open Source Software Development</li>
                            <li>Calculus II</li>
                            <li>Linear Algebra</li>
                            <li>Discrete Mathematics</li>
                        </ul>
                        <br/>
                        <strong>History Capstone:</strong><a href={history}>Hypocrisy and Pragmatism</a>
                        <br/>
                        <strong>Senior Individualized Project(Capstone):</strong><a href={SIP}>StakSwipe</a>
                    </div>
                </div>
                <div style={colStyle}  className='col'>
                    <h3 style={colHeaderStyle}>ExtraCurricular</h3>
                    <div style={{ padding: '0.5rem'}}>
                        <strong>Study Abroad in Hikone Japan</strong>
                        <br/>
                        I traveled to a Hikone Japan in Shiga prefecture to do a 4 Month language and cultural program at the Japan Center for Michigan Universities. Followed by a 2 month long intercultural research project into japanese sake brewing.
                        <br/>
                        <br/>
                        <strong>Ultimate Frisbee</strong>
                        <br/>
                        I played for the Ultimate Buzz club team at school all for four years as well as the community league Kalamazoo Ultimate Disc League. Captained the B team sophmore year. Went to D3 College regionals junior year wth the A team.
                        <br/>
                        <br/>
                        <strong>Computer Science Teaching Assistant</strong>
                        <br/>
                        I worked as a teaching assistant for the computer science department my sophmore through senior years. I provided in class support for the intro and mid level classes and held office hours after class for help. I also assisted the professors in grading assignments
                    </div>
                </div>
            </div>
        </div>
    )
}
