import React, { Component } from 'react'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { spring, AnimatedRoute, AnimatedSwitch } from 'react-router-transition';

import AppNavbar from './components/Navbar'
import HomePage from './components/pages/HomePage'
import Education from './components/pages/Education'
import Experience from './components/pages/Experience'
import Projects from './components/pages/Projects'
import Contact from './components/pages/Contact'
import mountain from './images/mountain.jpg'

export default class App extends Component {
  
  getForegroundStyle = () => {
    return {
        zIndex: '2',
        textAlign: 'center',
        top: '5rem',
        position: 'absolute',
        width: '100%',
        height: '100%'
    }
  }

  getBackgroundStyle = () => {
    return {
        backgroundImage: `url(${mountain})`,
        filter: 'blur(8px)',
        WebkitFilter: 'blur(8px)',
        height: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    }
  }

  // we need to map the `scale` prop we define below
  // to the transform style property
  mapStyles(styles) {
    return {
      opacity: styles.opacity,
      transform: `scale(${styles.scale})`,
    };
  }

  // wrap the `spring` helper to use a bouncy config
  bounce(val) {
    return spring(val, {
      stiffness: 330,
      damping: 22,
    });
  }

  // child matches will...
  bounceTransition = {
    // start in a transparent, upscaled state
    atEnter: {
      opacity: 0,
      scale: 1.2,
    },
    // leave in a transparent, downscaled state
    atLeave: {
      opacity: this.bounce(0),
      scale: this.bounce(0.8),
    },
    // and rest at an opaque, normally-scaled state
    atActive: {
      opacity: this.bounce(1),
      scale: this.bounce(1),
    },
  };

  render() {
    return (
      <div className="App">
        <Router style={{ textAlign:'center'}}>
          <AppNavbar />
            <div style={this.getBackgroundStyle()}></div>
            <div style={this.getForegroundStyle()}>
            <AnimatedSwitch
              atEnter={this.bounceTransition.atEnter}
              atLeave={this.bounceTransition.atLeave}
              atActive={this.bounceTransition.atActive}
              mapStyles={this.mapStyles}
              className="route-wrapper"
            >
                <Route exact path='/' component={HomePage} />
                <Route path='/education' component={Education} />
                <Route path='/experience' component={Experience} />
                <Route path='/projects' component={Projects} />
                <Route path='/contact' component={Contact} />
              </AnimatedSwitch>
            </div>
        </Router>
      </div>
    );
  }
}

