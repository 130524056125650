import React, { Component } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import {Link} from 'react-router-dom'

export default class AppNavbar extends Component {
    navbarStyle = {
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        zIndex:3
    }

    render() {
        return (
            <Navbar bg="light" expand="lg" style={this.navbarStyle}>
                <Navbar.Brand to='/'>
                    <Link className='navLink' to='/'>Home</Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav>
                            <Link className='navLink' to='/education'>Education</Link>
                        </Nav>
                        <Nav>
                            <Link className='navLink' to='/experience'>Experience</Link>
                        </Nav>
                        <Nav>
                            <Link className='navLink' to='/projects'>Projects</Link>
                        </Nav>
                        <Nav.Link>
                            <Link className='navLink' to='/contact'>Contact</Link>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}
